import React from "react";
import Navbar from "./Navbar";
import { FaShippingFast, FaRegClock, FaBoxOpen } from "react-icons/fa";
import Footer from "./Footer";
import { Link } from "react-router-dom";

import "./styles.scss";
import logoProex from "../../../assets/icons/logo-proex_oscuro-horizontal.png";

const Home = () => {
  return (
    <>
      <Navbar />

      <main className="hero">
        <section className="hero-section">
          <img src={logoProex} alt="Logo Proex" />
        </section>
      </main>

      <div className="container mt-5">
        <article>
          <div className="d-flex flex-column text-center">
            <h2 className="display-3 mb-4">Porqué Preferirnos</h2>
            <p className="text-muted">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eius,
              assumenda ex nesciunt eaque magnam quas nam doloremque magni quos
              obcaecati distinctio et facere praesentium neque pariatur vitae
              alias quo maxime.
            </p>
          </div>

          <div className="row p-4 mt-4">
            <div className="col-md-6 px-4">
              <div className="row">
                <div className="col-2">
                  <FaShippingFast color="#F4313F" size="100%" />
                </div>
                <div className="col-10">
                  <h3>Entrega Rápida</h3>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Reiciendis dolorem voluptatum molestias et odio nemo
                    deleniti fugiat repellendus minima nobis.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-4">
              <div className="row">
                <div className="col-2">
                  <FaRegClock color="#F4313F" size="100%" />
                </div>
                <div className="col-10">
                  <h3>Comodidad</h3>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Reiciendis dolorem voluptatum molestias et odio nemo
                    deleniti fugiat repellendus minima nobis.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>

      <article className="second-hero mt-3">
        <div className="hero-section">
          <h2>Entregamos en 24 - 48 horas hábiles</h2>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            Repellendus nisi eligendi adipisci laboriosam facilis molestias
            cumque consequuntur a, voluptatibus harum repudiandae accusamus
            ullam unde, dolorem, eius voluptatem blanditiis eveniet possimus quo
            deleniti delectus officiis laborum magnam vel. Assumenda, minima
            commodi!
          </p>

          <Link to="/login" className="btn btn-primary">
            Iniciar Sesión
          </Link>
        </div>
      </article>

      <div className="container">
        <div className="row py-5">
          <div className="col-md-6 d-flex justify-content-center align-items-center flex-column">
            <FaBoxOpen size="4rem" color="#F4313F" />
            <h3>Tarifa a Nivel Nacional</h3>
            <p className="text-muted">$2.50 peso/volumen</p>
          </div>
          <div className="col-md-6">
            <h2 className="display-5">¡Sin complicaciones!</h2>

            <p className="text-muted">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Molestiae nam debitis natus, pariatur reiciendis quibusdam!
            </p>
            <p className="text-muted">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Perferendis eligendi commodi eius eveniet, exercitationem, aliquam
              optio modi sit dolorem blanditiis non mollitia repellat provident
              fugiat error nemo unde, dolorum
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
