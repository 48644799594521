import React from "react";
import { Navbar as NavBar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import logoProex from "../../../assets/icons/logo-proex_oscuro-horizontal.png";

const Navbar = () => {
  return (
    <>
      <NavBar
        expand="lg"
        variant="dark"
        style={{ background: "#303C54" }}
        className="py-3"
      >
        <Container>
          <NavBar.Brand as={Link} to="/">
            <img
              src={logoProex}
              alt="Proex Logo"
              style={{ width: "120px", height: "auto" }}
            />
          </NavBar.Brand>
          <NavBar.Toggle aria-controls="basic-navbar-nav" />
          <NavBar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link to="/" as={Link}>
                Inicio
              </Nav.Link>
              <Nav.Link to="/tracking" as={Link}>
                Rastreo Aéreo
              </Nav.Link>
              <Nav.Link to="/tracking/ocean" as={Link}>
                Rastreo Marítimo
              </Nav.Link>
              <Nav.Link to="/login" as={Link}>
                Iniciar Sesión
              </Nav.Link>
            </Nav>
          </NavBar.Collapse>
        </Container>
      </NavBar>
    </>
  );
};

export default Navbar;
