import React from "react";
import logoProex from "../../../assets/icons/logo-proex_oscuro-horizontal.png";

import "./styles.scss";

const Footer = () => {
  return (
    <footer style={{ background: "#303C54" }} className="p-3 page-footer">
      <img src={logoProex} alt="Proex Logo" className="logo mb-2" />
      <p className="text-white m-0">
        Copiright &copy; 2021 ProEx. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
