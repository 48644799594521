import React, { useState } from "react";
import Navbar from "../home/Navbar";
import {
  FaSearch,
  FaPlane,
  FaTruckLoading,
  FaClipboardList,
  FaMapMarkerAlt,
  FaBoxOpen,
} from "react-icons/fa";
import axios from "axios";

import "./styles.scss";

const OceanTracking = () => {
  const [trackingNumber, setTrackingNumber] = useState("");
  const [data, setData] = useState([]);

  const submitHandler = async (e) => {
    e.preventDefault();

    const res = await axios.get(
      `${process.env.TRACKING_URL}/ocean-tracking/${trackingNumber}`
    );
    console.log(res.data);
    setData(res.data);
  };

  const clean = (e) => {
    e.preventDefault();

    setTrackingNumber("");
    setData([]);
  };

  return (
    <>
      <Navbar />

      <div className="container mt-5 mb-5">
        <h2 className="display-5 fw-bold text-center mb-5">
          Rastrear Paquetes de Carga Marítima
        </h2>

        <div className="d-flex flex-column align-items-center justify-content-center">
          <a href="#" className="align-self-end mr-2 mb-2" onClick={clean}>
            Borrar
          </a>

          <form className="w-100" onSubmit={submitHandler}>
            <div className="input-group">
              <input
                type="text"
                className="form-control py-4 px-3"
                placeholder="Ingrese el número de rastreo"
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
              />
              <div className="input-group-append">
                <button className="btn btn-red px-5" type="submit">
                  <FaSearch />
                </button>
              </div>
            </div>
          </form>
        </div>

        {data.length > 0 && (
          <div className="row mt-5">
            <div className="col-sm">
              <div className="card h-100">
                <div className="card-body pt-4 px-2 text-center">
                  <FaTruckLoading
                    size="3rem"
                    color={data[0].text !== "N/A" ? "#f4313f" : "#6C757D"}
                  />
                  <h4
                    className="mt-3"
                    style={{
                      color: data[0].text !== "N/A" ? "#f4313f" : "#6C757D",
                    }}
                  >
                    Origen
                  </h4>
                  <span className="text-muted">Entrada a bodega en Miami</span>
                  <h2 className="text-muted my-2">{data[0].text}</h2>
                  <span className="text-muted">{data[0].date}</span>
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="card h-100">
                <div className="card-body pt-4 px-2 text-center">
                  <FaMapMarkerAlt
                    size="3rem"
                    color={data[1].text !== "N/A" ? "#f4313f" : "#6C757D"}
                  />
                  <h4
                    className="mt-3"
                    style={{
                      color: data[1].text !== "N/A" ? "#f4313f" : "#6C757D",
                    }}
                  >
                    Sucursal
                  </h4>
                  <span className="text-muted">Disponible para retiro</span>
                  <h2 className="text-muted my-2">{data[1].text}</h2>
                  <span className="text-muted">{data[1].date}</span>
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="card h-100">
                <div className="card-body pt-4 px-2 text-center">
                  <FaBoxOpen
                    size="3rem"
                    color={data[2].text !== "N/A" ? "#f4313f" : "#6C757D"}
                  />
                  <h4
                    className="mt-3"
                    style={{
                      color: data[2].text !== "N/A" ? "#f4313f" : "#6C757D",
                    }}
                  >
                    Entregado
                  </h4>
                  <span className="text-muted">Retirado por el cliente</span>
                  <h2 className="text-muted my-2">{data[2].text}</h2>
                  <span className="text-muted">{data[2].date}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OceanTracking;
